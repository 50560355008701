import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { callList } from '@/api/ceshi_xiugai/whole';
import pagination from '@/components/pageNation/index';
export default {
  props: {
    name: String,
    cid: Number
  },
  name: 'callRecord',
  components: {
    pagination: pagination
  },
  data: function data() {
    return {
      activeName: 'first',
      tableData1: [],
      tableData2: [],
      tableData3: [],
      listLoading: false,
      query: {
        page1: 1,
        pagesize1: 10,
        page2: 1,
        pagesize2: 10,
        page3: 1,
        pagesize3: 10,
        total1: 0,
        //总条数，不是总页数
        total2: 0,
        total3: 0
      },
      create_stime1: new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 1),
      create_stime2: new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 30),
      create_etime: new Date()
    };
  },
  mounted: function mounted() {
    this.getList1();
    this.getList2();
    this.getList3();
  },
  methods: {
    handleSizeChange1: function handleSizeChange1(val) {
      this.query.pagesize1 = val;
      this.getList1();
    },
    //翻页
    handleCurrentChange1: function handleCurrentChange1(val) {
      this.query.page1 = val;
      this.getList1();
    },
    handleSizeChange2: function handleSizeChange2(val) {
      this.query.pagesize2 = val;
      this.getList2();
    },
    //翻页
    handleCurrentChange2: function handleCurrentChange2(val) {
      this.query.page2 = val;
      this.getList2();
    },
    handleSizeChange3: function handleSizeChange3(val) {
      this.query.pagesize3 = val;
      this.getList3();
    },
    //翻页
    handleCurrentChange3: function handleCurrentChange3(val) {
      this.query.page3 = val;
      this.getList3();
    },
    getList1: function getList1() {
      var _this = this;
      this.listLoading = true;
      callList({
        page: this.query.page1,
        pagesize: this.query.pagesize1,
        s_stime: this.create_stime1.toString().substring(0, 10),
        s_etime: Date.parse(this.create_etime).toString().substring(0, 10),
        s_customer_id: this.cid
      }).then(function (respomse) {
        _this.tableData1 = respomse.data.data;
        _this.query.total1 = respomse.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.tableData1 = [];
        _this.listLoading = false;
      });
    },
    getList2: function getList2() {
      var _this2 = this;
      this.listLoading = true;
      callList({
        page: this.query.page2,
        pagesize: this.query.pagesize2,
        s_stime: this.create_stime2.toString().substring(0, 10),
        s_etime: Date.parse(this.create_etime).toString().substring(0, 10),
        s_customer_id: this.cid
      }).then(function (respomse) {
        _this2.tableData2 = respomse.data.data;
        _this2.query.total2 = respomse.data.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.tableData2 = [];
        _this2.listLoading = false;
      });
    },
    getList3: function getList3() {
      var _this3 = this;
      this.listLoading = true;
      callList({
        page: this.query.page3,
        pagesize: this.query.pagesize3,
        s_stime: '',
        s_etime: '',
        s_customer_id: this.cid
      }).then(function (respomse) {
        _this3.tableData3 = respomse.data.data;
        _this3.query.total3 = respomse.data.total;
        _this3.listLoading = false;
      }).catch(function () {
        _this3.tableData3 = [];
        _this3.listLoading = false;
      });
    }
  }
};